<template>
    <header class="bheader">
        <div class="container">
            <div class="row">
                <div class="columns columns--right d-print-none" v-if="signUpDetected() || isCyberEasy()" id="user_controls">
                    <div class="bheader__user-info" v-if="!signUpDetected() && !isCyberEasy()">
                        <span v-if="user">Makler: {{user.firstname}} {{user.lastname}}</span>
                    </div>
                    <div class="large-10 columns">
                        <router-link :to="goBack()"
                                     v-if="$route.meta.backPageName && isCyberEasy()"
                                     class="btn-style back-zu">
                            <span>&lt;</span>Zurück
                        </router-link>
                        <a class="btn-style back-zu d-print-none"
                           v-if="signUpDetected()"
                           @click.prevent="backForm()">
                            <span>&lt;</span>
                            <span class="back-zu__text">Zurück</span>
                        </a>
                        <router-link
                                :to="'/beginnen/risikofragen/1'"
                                v-if="$route.name === 'Beratungstool'"
                                class="btn-style primary">Beratung starten
                        </router-link>
                        <a class="btn-style primary"
                           target="_self"
                           href="/beginnen/risikofragen2/1"
                           v-if="$route.name === 'Finish'">Jetzt abschließen</a>
                        <!--<a class="btn-style primary"-->
                        <!--target="_self"-->
                        <!--href="/versicherung/1a/ihr-unternehmen/branche"-->
                        <!--v-if="$route.name === 'Finish'">Jetzt abschließen</a>-->
                        <a class="btn-style primary"
                           @click.prevent.stop="emitNextPage()"
                           v-if="$route.name === 'Vectors'">Weiter zur Versicherung</a>
                    </div>
                </div>
                <div class="columns columns--left logo-col">
                    <div id="logo">
                        <router-link to="/">
                            <img v-if="isAon" src="/static/aon-logo.svg" alt="logo" class="aon-logo">
                            <img v-else src="https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/general/images/logo_blue.svg" alt="logo" class="cyber-logo">
                        </router-link>
                    </div>
                    <div class="heading d-none d-md-flex d-print-none text-capitalize">{{ user.brokerAgency ? user.brokerAgency.company : ''}}</div>
                </div>
                <div class="columns columns--right d-print-none" id="nav_toggle" v-if="!isCyberEasy()">
                    <div class="large-2 columns d-none d-md-flex bheader__user-info" v-if="user && !loginDetected">
                        <span v-if="user.firstname"><span class="bheader__makler-title"></span> {{user.firstname}} {{user.lastname}}</span>
                    </div>
                    <div class="large-2 columns bheader__icon-item bheader__notification" :class="{'bheader__notification--has-new': hasNewNotification}"
                        v-if="!loginDetected"
                        id="notification-link">
                        <icon name="notification" :width="15" color="black">
                        </icon>
                        <icon v-if="hasNewNotification" name="notification-waves" class="bheader__notification-waves">
                        </icon>
                    </div>
                    <NotificationTooltip v-if="!loginDetected">
                        <div class="notification-tt__body" v-for="ntf in notifications" :key="ntf.notification.id" @click="openModal(ntf.notification.id)">
                            <div class="notification-tt__top">
                                <div class="notification-tt__date d-flex flex-column" v-if="ntf.notification.event_start_date_optional && ntf.notification.event_end_date_optional">
                                    <span>
                                        {{ ntf.notification.event_start_date_optional | formatDate}}
                                    </span>
                                    <span>
                                        {{ ntf.notification.event_end_date_optional | formatDate }}
                                    </span>
                                </div>
                                <div class="notification-tt__title" >{{ ntf.notification.notification_header }}</div>
                            </div>
                            <div class="notification-tt__text" v-html="$sanitizeHtml(ntf.notification.notification_text)">
                            </div>
                            <div class="notification-tt__buttons">
                                <ButtonComponent
                                    v-if="ntf.notification.button_internal_redirect_optional"
                                    btnStyle="accent"
                                    btnSize="sm"
                                    :link="`/${ntf.notification.button_internal_redirect_optional}`"
                                    @click.native.stop                                >

                                {{ ntf.notification.notification_button_text }}
                                </ButtonComponent>
                                <ButtonComponent
                                    v-else
                                    btnStyle="accent"
                                    btnSize="sm"
                                    behavior="externalLink"
                                    :link="ntf.notification.notification_link_target"
                                >

                                {{ ntf.notification.notification_button_text }}
                                </ButtonComponent>

                                <ButtonComponent
                                    btnStyle="link-primary"
                                    behavior="button"
                                    :icon="{
                                        name: 'arrow-full-right',
                                        position: 'right'
                                    }"
                                    btnSize="sm"

                                    v-b-modal.event-modal

                                    @click="setModalContent(ntf.notification.id)"
                                >
                                <span class="pl-2">Mehr erfahren</span>
                                </ButtonComponent>
                            </div>
                        </div>
                    </NotificationTooltip>
                    <div class="large-2 columns bheader__icon-item" v-if="!loginDetected">
                        <a @click="openNav()"><i class="ion-navicon"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
  import eventBus from '@/components/shared/EventBus.js'
  import _ from 'lodash'
  import Icon from './Icon';
  import NotificationTooltip from '@/components/NotificationTooltip';
  import ButtonComponent from '@/components/reusable/ButtonComponent';
  import { mapGetters } from 'vuex'
  import moment from 'moment';

  export default {
    name: 'BHeader',
    props: ['user'],
    computed: {
        ...mapGetters(['notifications','hasNewNotification','isAon']),
        loginDetected() {
            return this.$route.path && this.$route.path.indexOf('/login') > -1
        },
        navState: {
            get() {
            return this.$store.state.navigationOpened
            },
            set(newValue) {
                this.$store.commit('SET_NAVIGATION', newValue)
            }
        },
        logo() {
            let logo = 'default'
            try {
                logo = this.isAon ? 'aon' : 'default'
            } catch {
                console.error('Can not get logo')
            }
            return logo
        }
    },
    mounted: function() {
    },
    components: {
      Icon,
      NotificationTooltip,
      ButtonComponent
    },
    methods: {
      openModal(id){
        this.$store.dispatch('SET_SEEN_NOTIFICATIONS', id)
        this.$store.commit('SET_EVENTMODAL_CONTENT', id)
        this.$bvModal.show('event-modal')
      },
      setModalContent(id) {
        this.$store.dispatch('SET_SEEN_NOTIFICATIONS', id)
        this.$store.commit('SET_EVENTMODAL_CONTENT', id)
      },
      openNav() {
        this.navState = !this.navState
        eventBus.$emit('openNav', this.navState)
      },
      isCyberEasy () {
        return ['Beratungstool', 'Finish', 'Vectors', 'Risikofragen'].some(el => {
          return this.$route.name === el
        }, this)
      },
      signUpDetected: function() {
        if (['abschluss'].indexOf(this.$route.name) > -1) {
            return false
        }
        return this.$route.path && this.$route.path.indexOf('/versicherung') > -1
      },
      backForm: function() {
        if (this.$route.params.id === '1a') {
          return this.$router.push(this.goBack())
        }
        eventBus.$emit('goBack')
      },
      goBack: function() {
        const backRoute = this.$route.meta.backPageName
        if (_.isObject(backRoute)) {
          return backRoute
        }

        if (!backRoute) {
          this.$router.go(-1)
        }

        if (backRoute.indexOf('|') === -1) {
          return backRoute
        }
        const id = parseInt(this.$route.params.id) - 1
        const split = backRoute.split('|')

        if (id <= 0) {
          return {name: split[0]}
        }

        return {
          name: this.$route.name,
          params: {id: id}
        }
      },
      emitNextPage: function() {
        eventBus.$emit('nextPageHit')
      }
    },
    filters: {
        formatDate: function (date) {
            if (!date) return ''
            return moment(date).format('DD.MM.YY')
        }
    }
  }
</script>

<style lang="scss" scoped>
    @import "../scss/variables";
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';
    @import "../scss/print/bHeader";

    #user_controls {
        padding-right: 0;

        .back-zu {
            display: inline-flex;
            align-items: center;
            gap: 0.5rem;

            span {
                position: relative;
                right: 2px;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        #user_controls {
            .back-zu {
                padding-left: 0;
            }

            a.btn-style.primary {
                text-align: center;
                padding: .4em .1em;
                font-size: .9rem;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        #user_controls {
            .btn-style, a.btn-style.primary {
                font-size: 1rem;
                padding: 0;
                margin-right: 0.5rem;
            }

            .back-zu__text {
                display: none;
            }
        }
    }

    .bheader {
        position: fixed;
        top: 0;
        padding: 0.75rem 0;
        width: 100%;
        background: #fff;
        z-index: $zIndexHeader;
        box-shadow: 0px 8px 8px rgba(88, 89, 91, 0.1);

        &__user-info {
            margin-right: 3rem;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .is-pma {
                color: $redTone;
                font-weight: bold;
            }
        }

        &__makler-title {
            color: $darkGrey;
        }

        #nav_toggle {
            > div {
                align-items: center;
                justify-content: center;
                display: flex;
                a {
                    color: #000;
                    font-size: 33px;
                    margin: 0;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                }

                a:hover {
                    color: $primary;
                }

                .ion-navicon {
                    display: flex;
                    align-items: center;
                    transition: 0.3s;
                }
            }

            .phone {
                position: relative;
                // padding-right: 9px;
                .phone-text {
                    display: inline-block;
                }
            }
        }

        #notification-link:hover {
            color: $primary;
        }

        &__icon-item {
            cursor: pointer;
        }

        &__icon-item + .bheader__icon-item {
            margin-left: 1.75rem;
        }

        &__notification {
            position: relative;
        }

        &__notification--has-new {
            position: relative;

            &::before {
                content: '';
                height: 10px;
                width: 10px;
                border-radius: 50%;
                background: #fff;
                position: absolute;
                top: 0;
                right: -2px;
                opacity: 0;
                animation: dot-notif .5s 2.5s forwards;
            }

            &::after {
                content: '';
                height: 6px;
                width: 6px;
                border-radius: 50%;
                background: $orangeTone;
                position: absolute;
                top: 2px;
                right: 0;
                opacity: 0;
                animation: dot-notif .5s 2.5s forwards;
            }
        }

        &__notification-waves {
            opacity: 0;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            transition: 0.2s;
            animation: waves-notif 1.5s 1s;
        }

        .row {
            #logo {
                a {
                    font-size: 1.5rem;
                    color: #000;
                    // margin-right: 5.25rem;

                    // @include media-breakpoint-down(md) {
                    //     margin-right: 3rem;
                    // }

                    img {
                        width: 175px;
                        max-height: 53px;

                        @include media-breakpoint-down(md) {
                            width: 175px;
                        }

                        @include media-breakpoint-down(sm) {
                            width: 150px;
                        }

                        @include media-breakpoint-down(xs) {
                            width: 119px;
                        }

                    }

                    .aon-logo {
                        max-height: 36px;
                        padding: 4px 0;
                        width: auto;

                        @include media-breakpoint-up(sm) {
                            max-height: 40px;
                        }
                    }
                }
            }
            .heading {
                font-weight: bold;
                font-size: 1.175rem;
            }
            .columns {
                text-align: right;
                // padding-top: 1rem;
                // padding-bottom: 1rem;
                // height: 72px;
                // padding-left: 0;
                // padding-right: 0;
                // a {
                //   display: inline-flex;
                //   align-items: center;
                //   padding-left: 8px;
                // }

                // .cd-logo-full {
                //   width: 170px;
                //   float: left;
                // }
                &--left {
                    flex-grow: 1;
                    padding-right: 15px;
                    padding-left: 15px;

                    .heading {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                    }
                }

                &--right {
                    padding-right: 15px;
                    padding-left: 15px;
                }
            }

            .columns:last-child {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                a:not(.primary) {
                    // margin-right: 20px;
                    border: 0;
                    color: $orangeTone;
                }
                .btn-style {

                }
            }

            > div:first-child,
            > .logo-col {
                // height: 70px;
                display: flex;
                align-items: center;
            }

        }
        svg {
            width: 31px;
            height: 31px;
        }
        $svgHover: #2c8cb8;

        .call-icon {

            .st0 {
                display: none;
            }
            .st1 {
                display: inline;
                fill: #2C8CB8;
            }
            .st2 {
                fill: #31A9E0;
            }

            &:hover {
                .st1, .st2 {
                    fill: $svgHover;
                }
            }
        }

        .chat-icon {
            .st0 {
                fill: #31A9E0;
            }
            &:hover {
                .st0 {
                    fill: $svgHover;
                }
            }
        }
    }

    @keyframes waves-notif {
        from {
            opacity: 0;
            transform: translateX(-50%) scale(1);
        }

        25% {
            opacity: 1;
            transform: translateX(-50%) scale(1.2);
        }

        50% {
            opacity: 0;
            transform: translateX(-50%) scale(1);
        }

        75% {
            opacity: 1;
            transform: translateX(-50%) scale(1.2);
        }

        to {
            opacity: 0;
            transform: translateX(-50%) scale(1);
        }
    }

    @keyframes dot-notif {
        from {
            opacity: 0;
            transform: scale(0);
        }

        to {
            opacity: 1;
            transform: scale(1);
        }

    }
</style>
